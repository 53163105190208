import * as React from 'react'
import { graphql } from 'gatsby'

function PageTemplate({ data: { mdx } }: any) {
  return (
<>
    </>
  )
}
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        root
      }
      body
    }
  }
`
export default PageTemplate
